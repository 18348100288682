<template>
    <section class="section section--head section--head-fixed section--gradient section--details-bg " >
      <template v-for="CatInfo in info" :key="CatInfo.id">
        <!-- <div class="section__bg "
          v-bind:style="{ 'background-image': 'url(https://www.ab3ad-sd.com/Content/Ab3ad/poster/back.jpg)', 'background-size': 'cover', 'background-position': 'top' }">
        </div> -->
      </template>
      <div class="container">
        <!-- article -->
        <div class="article " style="text-align: right;margin-top: 2vh;"> 
          <div class="row" style="margin-right: 0;margin-left: 0;">
            <template v-for="CatInfo in info" :key="CatInfo.id">
              <div class="col-12 " style="padding-right: 0;padding: 0;">
                <!-- trailer -->
                <a href="#" class="article__trailer open-video">
                  <img style="width:30px;margin-left : 15px" src="../../assets/cat_icon.png"/>
                  {{ CatInfo.name_ar }}
                </a>
                <div class="article__content">
                  <h1 style="font-family:inherit;font-size:inherit">{{ CatInfo.desc_ar }}</h1>
                </div>
              </div>
            </template>
            <!-- video player -->
            <div class="row" dir="rtl" style="margin-right: 0;margin-left: 0;">
              <div class="col-12">
                <h3 class="card__title"  style="margin-top: 5vh;font-weight: 600;"><a href="#">
                  <svg class="svg-icon" viewBox="0 0 20 20" style="margin-right: 0px; width: 17px; margin-left: 5px;" data-v-fb69f072=""><path fill="#52A9DC" d="M7.228,11.464H1.996c-0.723,0-1.308,0.587-1.308,1.309v5.232c0,0.722,0.585,1.308,1.308,1.308h5.232
                        c0.723,0,1.308-0.586,1.308-1.308v-5.232C8.536,12.051,7.95,11.464,7.228,11.464z M7.228,17.351c0,0.361-0.293,0.654-0.654,0.654
                        H2.649c-0.361,0-0.654-0.293-0.654-0.654v-3.924c0-0.361,0.292-0.654,0.654-0.654h3.924c0.361,0,0.654,0.293,0.654,0.654V17.351z
                        M17.692,11.464H12.46c-0.723,0-1.308,0.587-1.308,1.309v5.232c0,0.722,0.585,1.308,1.308,1.308h5.232
                        c0.722,0,1.308-0.586,1.308-1.308v-5.232C19,12.051,18.414,11.464,17.692,11.464z M17.692,17.351c0,0.361-0.293,0.654-0.654,0.654
                        h-3.924c-0.361,0-0.654-0.293-0.654-0.654v-3.924c0-0.361,0.293-0.654,0.654-0.654h3.924c0.361,0,0.654,0.293,0.654,0.654V17.351z
                        M7.228,1H1.996C1.273,1,0.688,1.585,0.688,2.308V7.54c0,0.723,0.585,1.308,1.308,1.308h5.232c0.723,0,1.308-0.585,1.308-1.308
                        V2.308C8.536,1.585,7.95,1,7.228,1z M7.228,6.886c0,0.361-0.293,0.654-0.654,0.654H2.649c-0.361,0-0.654-0.292-0.654-0.654V2.962
                        c0-0.361,0.292-0.654,0.654-0.654h3.924c0.361,0,0.654,0.292,0.654,0.654V6.886z M17.692,1H12.46c-0.723,0-1.308,0.585-1.308,1.308
                        V7.54c0,0.723,0.585,1.308,1.308,1.308h5.232C18.414,8.848,19,8.263,19,7.54V2.308C19,1.585,18.414,1,17.692,1z M17.692,6.886
                        c0,0.361-0.293,0.654-0.654,0.654h-3.924c-0.361,0-0.654-0.292-0.654-0.654V2.962c0-0.361,0.293-0.654,0.654-0.654h3.924
                        c0.361,0,0.654,0.292,0.654,0.654V6.886z" data-v-fb69f072=""></path></svg>التصنيفات</a></h3>
              </div>
              <div class="col-12">
                <div class="row row--grid">
                  <div class="col-6 col-sm-4 col-lg-3 col-xl-2" @click="ChangeData(Sub_cat.id)" style="margin-top: 10px;" v-for="Sub_cat in Subs" :key="Sub_cat.id">
              <div class="ms_rcnt_box marger_bottom30 pointer" style="margin-bottom: 10px;">
                <div class="ms_rcnt_box_img margin-zero card_category"><a @click="ChangeData(Sub_cat.id)" class="">
                  <img v-lazy="Sub_cat.img" alt="" class="img-fluid"></a>
                  <div class="ms_main_overlay">
                    <div class="ms_box_overlay"></div>
                  </div>
                </div>
                <div style="z-index: 999; display: block; position: relative;">
                  <h3><a @click="ChangeData(Sub_cat.id)"  class="text_size"
                      style="color: rgb(255, 255, 255); font-weight: 600; font-size: 15px;"></a></h3>
                </div>
              </div>
            </div>

            <div class="col-6 col-sm-4 col-lg-3 col-xl-2" @click="GotoConsluting()" style="margin-top: 10px;">
              <div class="ms_rcnt_box marger_bottom30 pointer" style="margin-bottom: 10px;">
                <div class="ms_rcnt_box_img margin-zero card_category"><a @click="GotoConsluting()" class="">
                  <img src="https://www.ab3ad-sd.com/Content/Ab3ad/poster/quizs.png" alt="" class="img-fluid"></a>
                  <div class="ms_main_overlay">
                    <div class="ms_box_overlay"></div>
                  </div>
                </div>
                <div style="z-index: 999; display: block; position: relative;">
                  <h3><a @click="GotoConsluting()"  class="text_size"
                      style="color: rgb(255, 255, 255); font-weight: 600; font-size: 15px;"></a></h3>
                </div>
              </div>
            </div>
                </div>
              </div>
  
              <div class="col-12">
                <h3 class="card__title" style="margin-top: 5vh;font-weight: 600;"><a style="color:#026097" href="#">
                    <svg style="width:35px;enable-background:new 0 0 32 32;" width="35px" height="35px"
                      viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                      <path fill="#52A9DC"
                        d="M464,72H48a32.036,32.036,0,0,0-32,32V408a32.036,32.036,0,0,0,32,32H464a32.036,32.036,0,0,0,32-32V104A32.036,32.036,0,0,0,464,72Zm0,336H48V104H464l.02,304Z"
                        class="ci-primary" />
                      <rect width="184" height="32" x="232" y="184" fill="#52A9DC" class="ci-primary" />
                      <rect width="240" height="32" x="176" y="256" fill="#52A9DC" class="ci-primary" />
                      <rect width="240" height="32" x="176" y="328" fill="#52A9DC" class="ci-primary" />
                      <polygon fill="#52A9DC"
                        points="88.923 144 88.923 272.923 188.095 203.503 88.923 144" class="ci-primary" />
                    </svg> كل المقاطع </a>
                </h3>
              </div>
              <div class="row row--grid">
                <div class="col-6 col-sm-4 col-lg-3 col-xl-2" v-for="publish in art" :key="publish.id">
                  <div id="busicard" @click="getFeed(publish)">
                    <div class="menu">
                      <div class="menu">
                        <div class="movembar">
                          <h4 class="texto">
                            <svg class="svg-icon" style="width: 20px;height:20px;margin-left:5px" viewBox="0 0 20 20">
                              <path fill="#fff" d="M11.088,2.542c0.063-0.146,0.103-0.306,0.103-0.476c0-0.657-0.534-1.19-1.19-1.19c-0.657,0-1.19,0.533-1.19,1.19c0,0.17,0.038,0.33,0.102,0.476c-4.085,0.535-7.243,4.021-7.243,8.252c0,4.601,3.73,8.332,8.332,8.332c4.601,0,8.331-3.73,8.331-8.332C18.331,6.562,15.173,3.076,11.088,2.542z M10,1.669c0.219,0,0.396,0.177,0.396,0.396S10.219,2.462,10,2.462c-0.22,0-0.397-0.177-0.397-0.396S9.78,1.669,10,1.669z M10,18.332c-4.163,0-7.538-3.375-7.538-7.539c0-4.163,3.375-7.538,7.538-7.538c4.162,0,7.538,3.375,7.538,7.538C17.538,14.957,14.162,18.332,10,18.332z M10.386,9.26c0.002-0.018,0.011-0.034,0.011-0.053V5.24c0-0.219-0.177-0.396-0.396-0.396c-0.22,0-0.397,0.177-0.397,0.396v3.967c0,0.019,0.008,0.035,0.011,0.053c-0.689,0.173-1.201,0.792-1.201,1.534c0,0.324,0.098,0.625,0.264,0.875c-0.079,0.014-0.155,0.043-0.216,0.104l-2.244,2.244c-0.155,0.154-0.155,0.406,0,0.561s0.406,0.154,0.561,0l2.244-2.242c0.061-0.062,0.091-0.139,0.104-0.217c0.251,0.166,0.551,0.264,0.875,0.264c0.876,0,1.587-0.711,1.587-1.587C11.587,10.052,11.075,9.433,10.386,9.26z M10,11.586c-0.438,0-0.793-0.354-0.793-0.792c0-0.438,0.355-0.792,0.793-0.792c0.438,0,0.793,0.355,0.793,0.792C10.793,11.232,10.438,11.586,10,11.586z">
                              </path>
                            </svg>
                            {{ publish.file_duration }}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div class="user pointer">
                      <img class="img2" v-lazy="publish.img">
                    </div>
                    <div class="bfollow pointer">
                      <a @click="getFeed(publish)">مشاهدة</a>
                    </div>
                    <div class="content pointer">
                      <p class="one-line-text">{{ publish.title_ar }}</p>
                      <h6 style="margin-top: -1vh;font-size: 85%;"> {{ publish.sub_cat_name }} </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </section>
    <!-- <InfiniteLoading @infinite="load" /> -->
  </template>
    
  <script>
  import { HTTP } from '@/Api/http-common';
  import { ref } from '@vue/reactivity';
  import { useRouter } from "vue-router"
  import { useCookie } from 'vue-cookie-next'
  export default {
    name: 'AppCategory',
    props: {
      id: String
    },
    async setup(props) {
      const router = useRouter();
      const cookie = useCookie();
      const art = ref([]);
      const Subs = ref([]);
      const info = ref([]);

      try {
        await HTTP.get(`GetAllContent.php?Content&catID=${props.id}&LIMIT=200&OFFSET=0`).then((res) => {
          art.value = res.data.Content;
        });
      } catch (err) {
        console.log(err);
      }
      
      try {
         HTTP.get(`GetSubCategories.php?cat_id=` + props.id ).then((res) => {
          Subs.value = res.data.Subs;
        });
      } catch (err) {
        console.log(err);
      }
      try {
         HTTP.get(`GetCategoryInfo.php?ID=` + props.id).then((res) => {
          info.value = res.data.CategoryInfo;
        });
      } catch (err) {
        console.log(err);
      }

    
      const getFeed = (publish) => {
        cookie.setCookie('cat_id', publish.cat_id);
        router.push({ name: "Content", params: { Content_id: publish.id } });
      };
      const GotoConsluting = () => {
        if(props.id == 1){
          router.push({ name: "Consulting", params: { sub_cat: '9' ,name_cat :'استشارات العلاج الطبيعي'} });
        }else{
          router.push({ name: "Consulting", params: { sub_cat: '10' ,name_cat :'استشارات الطب النفسي'} });
        }
        
        
      };
      const ChangeData = async (post) => {
            await HTTP.get(`GetContentBySubCategory.php?sub_cat_id=${post}&LIMIT=200&OFFSET=0`).then(res => {
              art.value = res.data.Content;
          })
        };
      return { info, art,Subs, getFeed,ChangeData,GotoConsluting };
    },
    mounted() {
      // let externalScript = document.createElement("script");
      // externalScript.setAttribute("src", "/Alprof/assets/js/main.js");
      // document.head.appendChild(externalScript);
  
  
      // let externalScript2 = document.createElement("script");
      // externalScript2.setAttribute("src", "/assets/js/owl.carousel.min.js");
      // document.head.appendChild(externalScript2);
    },
  }
  </script>
    
  <style>
    .card__rating {
        opacity: 1;
        transform: scale(1);
        height: 25px;
right: 113px;
width: 40%;
top: 0px;
}

.pointer {
  cursor: pointer;
}
  </style>
  <style scoped>

  .img2 {
    height: auto;
    width: 100%;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  #busicard {
    position: relative;
    background: linear-gradient(to left, #00D2C8, #179C8C);
    border-radius: 35px;
    height: 232px;
    max-width: 440px;
    margin: 10px auto;
  }
  #busicard h1 {
    color: #FAEAFF;
  }
  .movembar {
    display: flex;
      flex-direction: row;
      justify-content: baseline;
      align-items: flex-start;
  }
  .movegear {
    display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;
      align-items: flex-start;
    margin-top: -2px;
  }
  .mbar {
    width: 10px;
    height: 3px;
    background: #FAEAFF;
    position: absolute;
    margin: 17px 35px 0 36px;
  }
  .mbar:before {
    content: '';
    width: 12px;
    height: 3px;
    background: #FAEAFF;
    position: absolute;
    top: 0px;
    left: 0;
  }
  .mbar:after {
    content: '';
    width: 18px;
    height: 3px;
    background: #FAEAFF;
    position: absolute;
    top: 5px;
    left: 0;
  }
  .mbut:hover .mbar, .mbut:hover .mbar:before, .mbut:hover .mbar:after {
    background: #2F4858;
  }
  .user {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
          margin-top: -24px;
  }
  .user img {
    border-radius: 35px 35px 0 0;
  width: 100%;
  /* border: 4px solid #FAEAFF; */
  /* margin-top: 1vh; */
  height: 130px;
  }
  .user img:hover{
    filter: saturate(0);
  -webkit-filter: saturate(0);
  -moz-filter: saturate(0);
  }
  .gear {
    background-image:
      radial-gradient(at center center, #00D2C8 23%, #eee 25%, #FAEAFF 57%, transparent 53%),
      linear-gradient(0deg, transparent 43%, #eee 40%, #FAEAFF 60%, transparent 60%),
      linear-gradient(45deg, transparent 43%, #eee 43%, #FAEAFF 57%, transparent 57%),
      linear-gradient(90deg, transparent 40%, #eee 40%, #FAEAFF 60%, transparent 60%),
      linear-gradient(135deg, transparent 43%, #eee 43%, #FAEAFF 57%, transparent 57%);
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin: 17px 35px 0 36px;
  }
  .gear:hover {
    background-image:
      radial-gradient(at center center, #00D2C8 23%, #242424 25%, #2F4858 57%, transparent 53%),
      linear-gradient(0deg, transparent 43%, #242424 40%, #2F4858 60%, transparent 60%),
      linear-gradient(45deg, transparent 43%, #242424 43%, #2F4858 57%, transparent 57%),
      linear-gradient(90deg, transparent 40%, #242424 40%, #2F4858 60%, transparent 60%),
      linear-gradient(135deg, transparent 43%, #242424 43%, #2F4858 57%, transparent 57%);
  }
  .info li {
    color: #eee;
    font-size: 0.8em;
    display: inline-block;
    margin: 0 6px;
  }
  .hrtop {
    display: inline-block;
    background-color: #FAEAFF;
    width: 2px;
    height: 22px;
    margin: -2px auto;
  }
  .contacts {
    min-height: 100px;
  }
  .contacts i {
    display: inline-block;
    margin: 20px 0;
  }
  .contacts a {
    margin: 0 6px;
  }
  .bfollow {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    /*! margin: 25px 0 0 0; */
  }
  .bfollow a {
    position: absolute;
    box-shadow: 0 0 15px 1px #000;
    color: #FAEAFF;
    background-color: #1F937D;
    border-radius: 30px;
    padding: 7px 49px;
    transition: 350ms;
      -webkit-transition: all .3s;
  }
  .bfollow a:hover {
    font-weight: bold;
    color: #2F4858;
    background-color: #FAEAFF;
    padding: 8px 56px;
    transition: 350ms;
      -webkit-transition: all .3s;
  }
  .content {
    color: #FAEAFF;
    background: linear-gradient(to bottom, #2F4858, #2C687C);
    border-radius: 0 0 30px 30px;
    min-height: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .content p {
    min-width: 100%;
  border-top: none;
  text-align: center;
  font-family: "Noto Kufi Arabic";
  font-size: 16px;
  margin-top: 20px;
  }
  .lock {
    width: 26px;
    height: 16px;
    background: #FAEAFF;
    position: relative;
    border-radius: 4px;
    top: 30px;
  }
  .lock:before {
    content: '';
    width: 6px;
    height: 6px;
    background: transparent;
    border: 6px solid #FAEAFF;
    border-bottom: 0;
    border-radius: 6px 6px 0 0;
    position: absolute;
    bottom: 16px;
    right: 4px;
  }
  a .fa {
    font-size: 36px;
    transition: 500ms;
  }
  a:hover .fa {
    color: #2F4858;
    transition: 500ms;
  }
  a .fa-twitter-square, a .fa-instagram, a .fa-facebook-square {
    color: #FAEAFF;
  }
  
  .texto{
    margin-right: 15px;
  text-align: right;
  font-size: 15px;
  background: #1F937D;
  border-radius: 0 10px 10px 15px;
  padding: 0 16px;
  z-index: 9;
  font-weight: 900;
  }
  
  </style>
  
  